import '@css/main.scss';
import {Header} from './utils/Header';
import {Slider} from './utils/Slider';
import {Accordion} from './utils/Accordion'
import {Blog} from './utils/Blog';
import {Modal} from './utils/Modal';
import {Form} from './utils/Form'
//import {Animations} from "./utils/Animation";
import {Flash} from './utils/Flash'
import {Font} from "@js/utils/Font";
import { Password } from '@js/utils/Password'

window.onload = () => {

  new Password()
  new Header()
  new Flash()
  new Slider()
  new Accordion()
  new Blog()
  new Font()
  new Modal()
  new Form()

}
