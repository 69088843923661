export class Password {

  constructor () {
     this.showing = false

    this.showPassword = document.getElementById('show-pwd')
    this.hidePassword = document.getElementById('hide-pwd')

    if (this.showPassword) {
      this.showPassword.addEventListener('click', () => this.toggleShowPassword())
    }
    if (this.hidePassword) {
      this.hidePassword.addEventListener('click', () => this.toggleShowPassword())
    }
  }

  toggleShowPassword() {
    const passwordInput = document.getElementById('user_pass')

    this.showing = !this.showing
    if (this.showing) {
      passwordInput.setAttribute('type', 'text')
      this.showPassword.style.display = 'none'
      this.hidePassword.style.display = 'block'

    } else {
      passwordInput.setAttribute('type', 'password')
      this.showPassword.style.display = 'block'
      this.hidePassword.style.display = 'none'
    }
  }
}
