export class Header {
  constructor() {
    this.burger()
    this.scrollHandler()
  }

  burger() {
    this.header = document.querySelector('body header')
    if (this.header) {
      this.nav = document.querySelector('.header-nav__burger')
      const burger = document.querySelector('#burger')
      const menu = document.querySelector('.header-nav__nav')
      const search = document.querySelector('.header-search')
      const body = document.querySelector('body')

      if (burger) {
        burger.addEventListener('click', evt => {
          evt.preventDefault()
          burger.classList.toggle('active')
          this.nav.classList.toggle('expanded')
          body.classList.toggle('mobile-open')
        })
        window.addEventListener('resize', evt => {

          let breakpoint = burger.getAttribute('data-breakpoint')
          if (window.matchMedia("(max-width: " + breakpoint + "px)").matches) {
            menu.style.display = "none";
            burger.classList.remove('hidden');
            burger.classList.add('show');
          } else {
            menu.style.display = "block";
            burger.classList.remove('show');
            burger.classList.add('hidden');
          }

        })
      }

      const burgerSubmenus = document.querySelectorAll(".header-nav__burger .menu-item-has-children")
      const burgerSubSubmenu = document.querySelectorAll('.header-nav__burger .menu-item-has-children .sub-menu .menu-item-has-children')

      burgerSubmenus.forEach(t => {

        let trigger = t.querySelectorAll('a')[0].offsetHeight + 15
        let height = t.querySelector('.sub-menu').offsetHeight + trigger + 25

        t.style.setProperty('--max-height', height + 'px')
        t.style.setProperty('--init-height', trigger + 'px')

        const button = t.querySelector(':scope > .open-menu')
        const link = t.querySelector(':scope > a')

        link.addEventListener('click', e => {

          console.log('click pas ouf')

          if (t.classList.contains('open')) {
            return
          } else {
            e.preventDefault()
          }

          t.classList.add('open')

        })

        button.addEventListener('click', e => {

          if (t.classList.contains('open')) {
            t.classList.remove('open')
          } else {

            burgerSubSubmenu.forEach(ss => {
              ss.classList.remove('open')
            })

            t.classList.add('open')

          }

        })

      })
    }
  }

  scrollHandler() {

    if(this.header) {
      if (document.documentElement.scrollTop || document.body.scrollTop > 0) {
        this.header.classList.add('scrolled')
      }

      document.addEventListener('scroll', () => {
        let height = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;

        if (height > 0 && !this.header.classList.contains('scrolled')) {
          this.header.classList.toggle('scrolled')
        }
        if (height === 0 && this.header.classList.contains('scrolled')) {
          this.header.classList.toggle('scrolled')
        }
      })
    }

  }

}
